@charset "UTF-8";

@import '../scss/variables';

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    background-color: $primary !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

code,
pre {
    font-family: 'Fira Code', monospace !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: $theme-default !important;
    white-space: pre-wrap !important;
    // min-height: 100vh !important;
}

.tabs.is-medium {
    font-size: 1rem !important;
}
