.menu.sidebarMenuOpened {
    pointer-events: auto;
    transform: none;
    /* transition: transform 300ms linear; */
    width: 20rem;
}

.menu.animatable {
    transition: all 130ms ease-in;
}

.menu.sidebarMenuOpened.animatable {
    transition: all 330ms ease-out;
}

html.noScroll {
    overflow: hidden;
}

body.overlay {
    background-color: rgba(0, 0, 0, 0.4);
}
