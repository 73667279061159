@charset "UTF-8";

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue-100: #cfe2ff;
$blue-200: #9ec5fe;
$blue-300: #6ea8fe;
$blue-400: #3d8bfd;
$blue-500: #0d6efd;
$blue-600: #0a58ca;
$blue-700: #084298;
$blue-800: #052c65;
$blue-900: #212529;

/**
* BULMA
*/

$primary: #01111e;
$primary-light: #021f35;
$primary-dark: #000e17;
$primary-border: #1c1f2c;

$dark: #0e0e0e;
$light: $gray-100;

// $info: #3298dc;
// $warning: #ffdd57;
// $success: #9acc4e;

// $info: #ffdd57;
// $light: #3298dc;
// $warning: $gray-300;

// $light: #d487fe;
// $info: #ddf7fd;
// $warning: #ddf7fd;
// $info: #fdd96a;
// $warning: #fdd96a;
// $success: #9edf4b;
// $danger: #f63c5d;

$no-1: #60c4b6;
$no-2: #bc7cf7;
$no-3: #fdd37e;
$no-4: #9edf4b;
$no-5: #f63c5d;
$no-6: #73a0fe;
$no-7: #f98052;

$theme-default: $no-1;
$theme-title: $no-7;
$theme-text: $no-3;
$theme-array: $no-6;
$theme-link: $no-4;

$light: $theme-default;
$success: $theme-title;
$info: $theme-text;
$warning: $theme-array;
$link: $theme-link;

/**
* TABS
*/
$tabs-border-bottom-color: $primary-border;
$tabs-link-color: $gray-500;
$tabs-link-hover-border-bottom-color: $primary-border;
$tabs-link-hover-color: $gray-100;
$tabs-link-active-border-bottom-color: $primary-border;
$tabs-link-active-color: #fff;
$tabs-boxed-link-radius: 0;
$tabs-boxed-link-hover-background-color: $primary-dark;
$tabs-boxed-link-hover-border-bottom-color: $primary-border;
$tabs-boxed-link-active-background-color: $primary-light;
$tabs-boxed-link-active-border-color: $primary-border;

/**
* MENU
*/
$menu-item-color: $gray-600;
$menu-item-hover-color: #fff;
$menu-item-hover-background-color: $primary-dark;
$menu-item-active-color: #fff;
$menu-item-active-background-color: $primary-light;
// $menu-list-border-left: 0.1rem solid $gray-600;
$menu-list-border-left: 0;
$menu-label-color: $no-3;
